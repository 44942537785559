import antdHr from "antd/lib/locale-provider/hr_HR";
import hrMessages from "../locales/hr_HR.json";

const HrLang = {
  messages: {
    ...hrMessages,
  },
  antd: antdHr,
  locale: "hr-HR",
};
export default HrLang;
